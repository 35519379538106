@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-700 bg-slate-200 font-sans;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-brand tracking-tight;
}

#datasets {
    background:
            /* Shadow Cover TOP */
            linear-gradient(
                    theme('colors.slate.200'),
                    rgba(220, 220, 250, 0)
            ) center top,

                /* Shadow TOP */
            linear-gradient(
                    rgba(0, 0, 0, 0.15),
                    transparent
            ) center top;

    background-repeat: no-repeat;
    background-size: 100% 128px, 100% 16px;
    background-attachment: local, scroll;
}

* {
    @apply select-none;
}

p {
    @apply select-text;
}

#annotator * {
    outline: none;
}

#annotator .div-root-48 {
    display: none !important;
}

#annotator img,
#annotator canvas,
#annotator svg {
    display: inline;
    vertical-align: initial;
    max-width: initial;
}

#annotator button.MuiButtonBase-root {
    @apply z-40;
}

#annotator .div-root-8 {
    @apply bg-slate-100;
}

#annotator {
    @apply overflow-hidden;
}

.react-trello-lane {
    border: 0;
    background-color: initial;
}